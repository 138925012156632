import React, { useEffect, useState, useMemo } from 'react';
import { bool } from 'prop-types';
import SelectSingleFilterPlain from './SelectSingleFilterPlain';
import SelectSingleFilterPopup from './SelectSingleFilterPopup';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { parse } from '../../../util/urlHelpers';

const SelectSingleFilter = props => {
  let { showAsPopup, options, keyword = '', languageInCookie, handleKeyword, ...rest } = props;
  // const [keyword, setKeyword] = useState('');

  const id = rest.id;

  const language = languageInCookie;
  const lang = language == 'el' ? 'el' : 'en';
  if (id == 'SearchFiltersMobile.category') {
    options.sort((a, b) => a.label.localeCompare(b.label, lang, { sensitivity: 'base' }));
  }

  const intl = useIntl();
  const history = useHistory();
  const location = history?.location;
  const search = parse(location?.search);

  let filterKeywordOptions = [...(options ?? [])];
  filterKeywordOptions = useMemo(() => {
    if (keyword !== '' && id == 'SearchFiltersMobile.designer') {
      return [...filterKeywordOptions].filter(item =>
        item.label.toLowerCase().includes(keyword?.toLowerCase())
      );
    } else {
      return options;
    }
  }, [keyword, options]);
  // const handleKeyword = e => {
  //   setKeyword(e.target.value);
  // };
  return showAsPopup ? (
    <SelectSingleFilterPopup {...rest} />
  ) : (
    <SelectSingleFilterPlain
      {...rest}
      keyword={keyword}
      handleKeyword={handleKeyword}
      intl={intl}
      search={search}
      location={location}
      options={
        filterKeywordOptions?.length > 0 ? [...new Set([...(filterKeywordOptions || [])])] : []
      }
    />
  );
};

SelectSingleFilter.defaultProps = {
  showAsPopup: false,
};

SelectSingleFilter.propTypes = {
  showAsPopup: bool,
};

export default SelectSingleFilter;
